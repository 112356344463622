
import React, { Suspense } from 'react';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { ApolloProvider } from '@apollo/react-hooks';

import App from './App';

const Loader = () => (
  <div>Loading...</div>
)

const httpLink = createHttpLink({
  uri: 'https://icfinanse.pl/graphql'
})

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
})

export default (
  <Suspense fallback={<Loader />}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Suspense>
)