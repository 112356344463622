import gql from 'graphql-tag';

export const FETCH_ARTICLES_QUERY = gql`
{
  getArticles {
    id
    title
    titleEn
    imageUrl
    body
    bodyEn
    createdAt
    category
    username
  }
}
`

export const FETCH_ARTICLE_QUERY = gql`
  query($articleId: ID!) {
    getArticle(articleId: $articleId) {
      id
      title
      titleEn
      imageUrl
      body
      bodyEn
      createdAt
      category
      username
    }
  }
`

export const FETCH_ARTICLES_PAGINATOR_QUERY = gql`
  query($page: Int, $limit: Int) {
    getArticlesWithPagination(page: $page, limit: $limit) {
      paginator {
        articleCount
        perPage
        pageCount
        currentPage
        slNo
        hasPrevPage
        hasNextPage
        prev
        next
      }
      articles {
        id
        title
        titleEn
        imageUrl
        body
        bodyEn
        createdAt
        username
        category
      }
    }
  }
`