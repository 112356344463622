import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Transition } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { FETCH_ARTICLES_QUERY } from '../util/graphql';

import Banner from '../components/Banner';
import ArticleCard from '../components/ArticleCard';
import { Link } from 'react-router-dom';

const Home = () => {
  const { t } = useTranslation();

  const {
    loading,
    data: { getArticles: articles } = {}
  } = useQuery(FETCH_ARTICLES_QUERY);

  return (
    <>
      <Banner />
      <div className="page">
        <h1 className="page__headline home__headline">{t('homepage.headline')}</h1>
        {loading ? (
          <h2 className="page__loading">{t('homepage.pageLoading')}</h2>
        ) : (
            <div className="page__article">
              <Transition.Group>
                {articles && articles.slice(0, 5).map(article => (
                  <ArticleCard key={article.id} article={article} />
                ))}
              </Transition.Group>
            </div>
          )}
        <Link className="page__mainLink" to="/aktualnosci">{t('homepage.cta')}</Link>
      </div>
    </>
  );
}

export default Home;