import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pl';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

if (i18next.language === 'pl') moment.lang('pl');
else if (i18next.language === 'en') moment.lang('en');


const ArticleCard = ({

  article: { id, title, titleEn, imageUrl, body, bodyEn, createdAt, category }
}) => {
  const { t } = useTranslation();

  const checkCategory = (category) => {
    if (category.includes('IMPORTANT_DATES')) {
      if (i18next.language === "pl") return "Ważne terminy"
      else if (i18next.language === "en") return "Importand dates"
    } else if (category.includes("VARIETY")) {
      if (i18next.language === "pl") return "Rozmaitości"
      else if (i18next.language === "en") return "Variety"
    } else if (category.includes("REGULATIONS")) {
      if (i18next.language === "pl") return "Przepisy"
      else if (i18next.language === "en") return "Regulations"
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  return (
    <div className="article">
      <div className="article__top">
        <span className="article__time">{moment(createdAt).fromNow(true)} {t('news.ago')}</span>
        <h4 className="article__category">{checkCategory(category)}</h4>
      </div>
      {imageUrl ?
        <img src={imageUrl} alt={title} className="article__thumbnail" />
        : null}
      <h2 className="article__title">{i18next.language === "pl" ? title : (titleEn === null ? title : titleEn)}</h2>

      {i18next.language === "pl" ?
        <p className="article__body" dangerouslySetInnerHTML={{ __html: body.substring(0, 250).concat('...') }} /> : (
          bodyEn === null ? <p className="article__body" dangerouslySetInnerHTML={{ __html: body.substring(0, 250).concat('...') }} /> : <p className="article__body" dangerouslySetInnerHTML={{ __html: bodyEn.substring(0, 250).concat('...') }} />
        )
      }

      <div className="article__bottom">
        <Link onClick={scrollToTop} className="article__button" to={`/aktualnosci/${id}`}>{t('news.cta2')}</Link>
      </div>
    </div>
  );
}

export default ArticleCard;