import React from 'react';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="page">
      <h1 className="page__headline">{t('about.headline')}</h1>
      <p className="page__content-container">
        <p className="page__content">{t('about.content1')}</p>
        <p className="page__content">{t('about.content2')}</p>
        <p className="page__content">{t('about.content3')}</p>
      </p>
    </div>
  );
}

export default About;