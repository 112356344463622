import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DriversTime = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page">
        <h1 className="page__headline">{t('services.service2.headline')}</h1>
        <div className="page__content-container">
          <p className="page__content">{t('services.service2.content1')}</p>
          <p className="page__content">{t('services.service2.list.headline')}</p>
          <ul className="page__content">
            <li className="page__content--list">{t('services.service2.list.item1')}</li>
            <li className="page__content--list">{t('services.service2.list.item2')}</li>
            <li className="page__content--list">{t('services.service2.list.item3')}</li>
            <li className="page__content--list">{t('services.service2.list.item4')}</li>
            <li className="page__content--list">{t('services.service2.list.item5')}</li>
          </ul>
          <p className="page__content">{t('services.service2.content2')}</p>
          <p className="page__content">{t('services.service2.content3')}</p>
          <p className="page__content">{t('services.service2.content5')}
            <Link className="page__link" to="/kontakt\">{t('services.service2.cta')}</Link>.</p>
        </div>
      </div>
    </>
  );
}

export default DriversTime;