import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page">
        <h1 className="page__headline">{t('privacyPolicy.headline')}</h1>
        <div className="page__content-container">
          <ol className="page__content">
            <li className="page__content--list">{t('privacyPolicy.list1')}<br />
              {t('privacyPolicy.content1')}
            </li>
            <li className="page__content--list">{t('privacyPolicy.list2')}<br />
              <strong>IC Finanse Sp. z o. o.</strong>{t('privacyPolicy.content2Regular')}<strong>{t('privacyPolicy.content2Strong2')}</strong></li>
            <li className="page__content--list">{t('privacyPolicy.list3')}</li>
            <li className="page__content--list">{t('privacyPolicy.list4')}</li>
            <li className="page__content--list">{t('privacyPolicy.list5')}
              <ul>
                <li>{t('privacyPolicy.secondList1')}</li>
                <li>{t('privacyPolicy.secondList2')}</li>
              </ul>
            </li>
            <li className="page__content--list">{t('privacyPolicy.list6')}</li>
            <li className="page__content--list">{t('privacyPolicy.list7')}</li>
            <li className="page__content--list">{t('privacyPolicy.list8')}</li>
            <li className="page__content--list">{t('privacyPolicy.list9')}</li>
            <li className="page__content--list">{t('privacyPolicy.list10')}</li>
            <li className="page__content--list">{t('privacyPolicy.list11a')}<a className="page__link" href="http://icfinanse.pl">www.icfinanse.pl</a>{t('privacyPolicy.list11b')}</li>
          </ol>
          <p className="page__content">{t('privacyPolicy.list12')}<Link className="page__link" to="/kontakt">{t('privacyPolicy.cta')}</Link>.</p>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;