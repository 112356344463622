import React from 'react';
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page">
        <div className="page__headline">{t('services.headline')}</div>
      </div>
    </>
  );
}

export default Services;