import React from 'react';
import { useTranslation } from 'react-i18next';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="banner__background"></div>
      <div className="banner__image"></div>
      <div className="banner__container">
        <img className="banner__logo" src="/images/logo.png" alt="IC Finanse" />
        <h1 className="banner__headline">{t('banner.headline')}</h1>
      </div>
    </>
  );
}

export default Banner;