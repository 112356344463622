import React from 'react';
import { useTranslation } from 'react-i18next';

const BHP = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page">
        <h1 className="page__headline">{t('services.headline')}</h1>
        <p className="page__content">{t('services.service5.list.headline')}</p>
        <ul className="page__content">
          <li className="page__content--list">{t('services.service5.list.item1')}</li>
          <li className="page__content--list">{t('services.service5.list.item2')}</li>
          <li className="page__content--list">{t('services.service5.list.item3')}</li>
        </ul>
        <p className="page__content">{t('services.service5.content')}</p>
      </div>
    </>
  );
}

export default BHP;