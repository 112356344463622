import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  return (
    <>
      <footer className="footer">
        <div className="footer__container">
          <div className="footer__column--first">
            <img className="footer__logo" src="/images/logo.png" alt="IC Finanse" />
            <div className="footer__info">
              <h2 className="footer__headline">{t('footer.col1.headline')}</h2>
              <p className="footer__content--main">IC FINANSE SP. Z O.O.</p>
              <p className="footer__content--secondary">{t('footer.col1.address2a')}<br />
                {t('footer.col1.address2b')}<br />
                {t('footer.col1.address2c')}</p>
              <p className="footer__content--secondary">
                {t('footer.col1.hours1')}<br />
                {t('footer.col1.hours2')} <span class="hours">10:00-16:00</span><br />
                {t('footer.col1.hours3')} <span class="hours">dzień wewnętrzny</span><br />
                <strong class="bottom-info">{t('footer.col1.hours4')}</strong></p>
              <p className="footer__content--secondary">{t('footer.col1.address3a')}<br />
                +48 530 631 524<br />
                +48 515 465 104</p>
            </div>
            <div className="footer__info">
              <h2 className="footer__headline">{t('footer.col1.additional.headline')}</h2>
              <div className="footer__additional">
                <div className="footer__addition--col1">
                  <span>{t('footer.col1.additional.nip')}</span>
                  <span>{t('footer.col1.additional.regon')}</span>
                  <span>{t('footer.col1.additional.krs')}</span>
                </div>
                <div className="footer__addition--col2">
                  <span>7010499039</span>
                  <span>362166119</span>
                  <span>0000569344</span>
                </div>
              </div>
              <Link className="footer__link" to="/polityka-prywatnosci" onClick={scrollToTop}>{t('footer.col1.privacyPolicy')}</Link>
              <p className="footer__content--secondary">{t('footer.col1.privacyContent')}.</p>
            </div>

          </div>

          <div className="footer__column--second">
            <Link onClick={scrollToTop} className="footer__menu" to="/o-firmie" >{t('menu.menu1')}</Link>
            <Link onClick={scrollToTop} className="footer__menu" to="/uslugi" >{t('menu.menu2')}</Link>
            <Link onClick={scrollToTop} className="footer__submenu" to="/uslugi/ksiegowosc" >{t('menu.menu3')}</Link>
            <Link onClick={scrollToTop} className="footer__submenu" to="/uslugi/rozliczanie-czasu-pracy-kierowcow" >{t('menu.menu4')}</Link>
            <Link onClick={scrollToTop} className="footer__submenu" to="/uslugi/kadry-i-place" >{t('menu.menu5')}</Link>
            <Link onClick={scrollToTop} className="footer__submenu" to="/uslugi/szkolenia-bhp" >{t('menu.menu6')}</Link>
            <Link onClick={scrollToTop} className="footer__submenu" to="/uslugi/uslugi-dodatkowe" >{t('menu.menu7')}</Link>
            <Link onClick={scrollToTop} className="footer__menu" to="/aktualnosci" >{t('menu.menu8')}</Link>
            <Link onClick={scrollToTop} className="footer__menu" to="/kontakt" >{t('menu.menu9')}</Link>
          </div>

          <div className="footer__column--third">
            <div class="footer__map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6713.55381008174!2d21.685325018163127!3d52.07193333350978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471f34a9f56bb801%3A0x83f5ef5d41182e7b!2sNowodzielnik%2013%2C%2005-332%20Nowodzielnik!5e0!3m2!1spl!2spl!4v1627808460040!5m2!1spl!2spl" allowfullscreen="true" title="Mapa dojazdowa do firmy IC Finanse sp. z o.o." loading="lazy"></iframe>
            </div>
            <div class="fb-page footer-fb" data-href="https://www.facebook.com/icfinansespzoo" data-tabs="" data-width="300px" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/icfinansespzoo" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/icfinansespzoo">IC Finanse Sp z oo</a></blockquote></div>
          </div>
        </div>

        <hr />

        <div className="footer__bottom">
          <p className="footer__copyright">© 2021 <a rel="noreferrer" target="_blank" href="http://wilinski-devit.pl" className="footer__link">Piotr Wiliński devIT</a><br />{t('copyright')}</p>
        </div>
      </footer>
    </>
  );
}

export default Footer;