import React from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const matches = useMediaQuery('(max-width: 768px)');

  const validationSchema = yup.object({
    name: yup
      .string(t('contact.form.name'))
      .required(t('contact.form.isRequired.name')),
    email: yup
      .string(t('contact.form.email'))
      .email(t('contact.form.isRequired.email'))
      .required(t('contact.form.isRequired.emailValid')),
    phone: yup
      .string(t('contact.form.phone'))
      .required(t('contact.form.isRequired.phone')),
    topic: yup
      .string(t('contact.form.topic'))
      .required(t('contact.form.isRequired.topic')),
    message: yup
      .string(t('contact.form.message'))
      .required(t('contact.form.isRequired.message')),
    personalData: yup
      .string(t('contact.form.personalData'))
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      topic: '',
      message: '',
      personalData: false
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      axios({
        method: "POST",
        url: '/kontakt',
        data: { ...values }
      }).then((response) => {
        if (response.data.status === 'sent') {
          alert("Message sent")
        } else if (response.data.status === 'failed') {
          alert("Message failed")
          console.log(response)
        }
      })
    }
  })

  return (
    <div className="page">
      <h2 className="page__headline">{t('contact.headline')}</h2>
      <ThemeProvider theme={theme}>
        <form
          onSubmit={formik.handleSubmit}
          className="contact"
          id="contact-form"
          method="POST"
        >
          <h3 className="contact__subheadline">{t('contact.title')}</h3>
          <div className="contact__group">
            <TextFieldWithStyles
              className={matches ? classes.fullWidth1 : classes.notFullWidth}
              id="name"
              name="name"
              label={t('contact.form.name')}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              variant="outlined"
              InputLabelProps={{
                classes: {
                  root: classes.input,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputProps={{
                classes: {
                  root: classes.inputWhite,
                  notchedOutline: classes.notchedOutline
                }
              }}
            />
          </div>
          <div className="contact__group">
            <TextFieldWithStyles
              className={matches ? classes.fullWidth1 : classes.notFullWidth}
              id="email"
              name="email"
              label={t('contact.form.email')}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              variant="outlined"
              InputLabelProps={{
                classes: {
                  root: classes.input,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputProps={{
                classes: {
                  root: classes.inputWhite,
                  notchedOutline: classes.notchedOutline
                }
              }}
            />
          </div>
          <div className="contact__group">
            <TextFieldWithStyles
              className={matches ? classes.fullWidth1 : classes.notFullWidth}
              id="phone"
              name="phone"
              label={t('contact.form.phone')}
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              variant="outlined"
              InputLabelProps={{
                classes: {
                  root: classes.input,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputProps={{
                classes: {
                  root: classes.inputWhite,
                  notchedOutline: classes.notchedOutline
                }
              }}
            />
          </div>
          <div className="contact__group">
            <TextFieldWithStyles
              className={matches ? classes.fullWidth1 : classes.notFullWidth}
              id="topic"
              name="topic"
              label={t('contact.form.topic')}
              value={formik.values.topic}
              onChange={formik.handleChange}
              error={formik.touched.topic && Boolean(formik.errors.topic)}
              helperText={formik.touched.topic && formik.errors.topic}
              variant="outlined"
              InputLabelProps={{
                classes: {
                  root: classes.input,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputProps={{
                classes: {
                  root: classes.inputWhite,
                  notchedOutline: classes.notchedOutline
                }
              }}
            />
          </div>
          <div className="contact__group">
            <TextFieldWithStyles
              className={matches ? classes.fullWidth1 : classes.notFullWidth}
              id="message"
              name="message"
              label={t('contact.form.message')}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
              variant="outlined"
              multiline
              rows={6}
              InputLabelProps={{
                classes: {
                  root: classes.input,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputProps={{
                classes: {
                  root: classes.inputWhite,
                  notchedOutline: classes.notchedOutline
                }
              }}
            />
          </div>
          <div className="contact__group">
            <FormControlLabel
              control={
                <Checkbox
                  id="personalData"
                  name="personalData"
                  checked={formik.values.personalData}
                  onChange={formik.handleChange}
                />
              }
              label={t('contact.form.personalData')}
            />
          </div>
          <button className="page__mainLink" type="submit">Wyślij</button>
        </form>
      </ThemeProvider>
    </div>
  );
}

const theme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        "&$focused": {
          color: '#b80b09',
        }
      }
    }
  }
})

const useStyles = makeStyles({
  notFullWidth: {
    width: "60%",
  },
  fullWidth1: {
    width: '100%',
    marginBottom: '4%'
  },
  fullWidth2: {
    width: '100%',
  },
  input: {
    color: "#666"
  },
  inputWhite: {
    color: "#444"
  },
  notchedOutline: {
    borderColor: "#666"
  }
})

const TextFieldWithStyles = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#b80b09'
      },
      '&.Mui-focused fieldset': {
        borderColor: "#b80b09"
      }
    }
  },
})(TextField)

export default Contact;