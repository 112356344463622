import React from 'react';
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import 'moment/locale/pl';
import { FacebookIcon, FacebookShareButton } from 'react-share';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { FETCH_ARTICLE_QUERY } from '../util/graphql';

if (i18next.language === 'pl') moment.lang('pl');
else if (i18next.language === 'en') moment.lang('en');

const SingleArticle = (props) => {
  const { t } = useTranslation();

  const articleId = props.match.params.articleId;

  const { data: { getArticle } = {} } = useQuery(FETCH_ARTICLE_QUERY, {
    variables: {
      articleId
    }
  })

  const checkCategory = (category) => {
    if (category.includes('IMPORTANT_DATES')) {
      if (i18next.language === "pl") return "Ważne terminy"
      else if (i18next.language === "en") return "Importand dates"
    } else if (category.includes("VARIETY")) {
      if (i18next.language === "pl") return "Rozmaitości"
      else if (i18next.language === "en") return "Variety"
    } else if (category.includes("REGULATIONS")) {
      if (i18next.language === "pl") return "Przepisy"
      else if (i18next.language === "en") return "Regulations"
    }
  }

  let articleMarkup;

  if (!getArticle) {
    articleMarkup = <h2>{t('news.loading')}</h2>
  } else {
    const { id, title, titleEn, imageUrl, body, bodyEn, createdAt, category } = getArticle

    articleMarkup = (
      <div className="article">
        <div className="article__top">
          <span className="article__time">{moment(createdAt).fromNow(true)} {t('news.ago')}</span>
          <h4 className="article__category">{checkCategory(category)}</h4>
        </div>
        {imageUrl ?
          <img src={imageUrl} alt={title} className="article__thumbnail" />
          : null}

        <h2 className="article__title">{i18next.language === "pl" ? title : (titleEn === null ? title : titleEn)}</h2>

        {i18next.language === "pl" ?
          <p className="article__body" dangerouslySetInnerHTML={{ __html: body }} /> : (
            bodyEn === null ? <p className="article__body" dangerouslySetInnerHTML={{ __html: body }} /> : <p className="article__body" dangerouslySetInnerHTML={{ __html: bodyEn }} />
          )
        }

        <FacebookShareButton className="article__share" quote={title} url={`http://icfinanse.pl/aktualnosci/${id}`}>
          <FacebookIcon size={35} round={true} />
        </FacebookShareButton>
      </div>
    )
  }

  return (
    <>
      <div className="page">
        {articleMarkup}
        <button className="page__mainLink" onClick={props.history.goBack}>Powrót</button>
      </div>
    </>
  );
}

export default withRouter(SingleArticle);