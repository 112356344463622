import React, { useEffect, useState } from 'react';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)
  }, []);

  return (
    <>
      { isVisible &&
        <div className="scrollToTop">
          <span className="icon-arrow_upward" onClick={scrollToTop}></span>
        </div>
      }
    </>
  );
}

export default ScrollToTop;