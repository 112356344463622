import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const HR = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page">
        <h1 className="page__headline">{t('services.service3.headline')}</h1>
        <div className="page__content-container">
          <p className="page__content">{t('services.service3.content1')}</p>
          <p className="page__content">{t('services.service3.content2')}</p>
          <p className="page__content">{t('services.service3.content3')}</p>
          <p className="page__content">{t('services.service3.content4')}</p>
          <p className="page__content">{t('services.service3.content5')}<Link className="page__link" to="/kontakt">{t('services.service3.cta')}</Link>.</p>
        </div>
      </div>
    </>
  );
}

export default HR;