import React from 'react';
import { useTranslation } from 'react-i18next';

const Accountancy = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page">
        <h1 className="page__headline">{t('services.service1.headline')}</h1>
        <div className="page__content-container">
          <p className="page__content">{t('services.service1.content1')}</p>
          <p className="page__content">{t('services.service1.list.headline')}</p>
          <ul className="page__content">
            <li className="page__content--list">{t('services.service1.list.item1')}</li>
            <li className="page__content--list">{t('services.service1.list.item2')}</li>
            <li className="page__content--list">{t('services.service1.list.item3')}</li>
            <li className="page__content--list">{t('services.service1.list.item4')}</li>
            <li className="page__content--list">{t('services.service1.list.item5')}</li>
            <li className="page__content--list">{t('services.service1.list.item6')}</li>
            <li className="page__content--list">{t('services.service1.list.item7')}</li>
            <li className="page__content--list">{t('services.service1.list.item8')}</li>
            <li className="page__content--list">{t('services.service1.list.item9')}</li>
          </ul>
          <p className="page__content">{t('services.service1.content2')}</p>
          <p className="page__content">{t('services.service1.content3')}</p>
          <p className="page__content">{t('services.service1.content5')}</p>
          <div className="page__logos">
            <div><img src="/images/oscbr.png" alt="OSCBR" /></div>
            <div><img src="/images/sk.png" alt="Superksięgowa" /></div>
            <div><img src="/images/mbank.png" alt="mBank" /></div>
          </div>
          <p className="page__content">{t('services.service1.content4')}</p>
        </div>
      </div>
    </>
  );
}

export default Accountancy;