import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Hamburger from './Hamburger'
import ClickableBackground from './ClickableBackground'

const Menu = () => {
  const { t } = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <>
      <header className="menu__container">
        <div className="menu">
          <div className="menu__logo">
            <Link to="/" exact>
              <img src="/images/logo.png" alt="IC Finanse" />
            </Link>
          </div>
          <div className={`menu__items ${isMenuOpen ? "active" : ""}`}>
            <NavLink className="menu__item" to="/" exact>Home</NavLink>
            <NavLink className="menu__item" to="/o-firmie" exact>{t('menu.menu1')}</NavLink>
            <div className="menu__item menu__dropdownitem">
              {t('menu.menu2')} <span className="icon-keyboard_arrow_down"></span>
              <div className="menu__dropdown">
                <NavLink
                  className="menu__subitem"
                  to="/uslugi/ksiegowosc"
                  exact
                >{t('menu.menu3')}</NavLink>
                <NavLink
                  className="menu__subitem"
                  to="/uslugi/rozliczanie-czasu-pracy-kierowcow"
                  exact
                >{t('menu.menu4')}</NavLink>
                <NavLink
                  className="menu__subitem"
                  to="/uslugi/kadry-i-place"
                  exact
                >{t('menu.menu5')}</NavLink>
                <NavLink
                  className="menu__subitem"
                  to="/uslugi/szkolenia-bhp"
                  exact
                >{t('menu.menu6')}</NavLink>
                <NavLink
                  className="menu__subitem"
                  to="/uslugi/uslugi-dodatkowe"
                  exact
                >{t('menu.menu7')}</NavLink>
              </div>
            </div>
            <NavLink className="menu__item" to="/aktualnosci" exact>{t('menu.menu8')}</NavLink>
            <NavLink className="menu__item" to="/kontakt" exact>{t('menu.menu9')}</NavLink>
          </div>
          <Hamburger isActive={isMenuOpen}
            click={() => setIsMenuOpen(true)}
          />
        </div>
      </header>
      {isMenuOpen ?
        <ClickableBackground click={() => setIsMenuOpen(false)} /> :
        null
      }
    </>
  );
}

export default Menu;