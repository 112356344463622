import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Transition } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { FETCH_ARTICLES_PAGINATOR_QUERY } from '../util/graphql';

import ArticleCard from '../components/ArticleCard';

const Articles = () => {
  const { t } = useTranslation();

  const [limit, setLimit] = useState(10);
  const page = 1;

  const {
    data: { getArticlesWithPagination } = {}
  } = useQuery(FETCH_ARTICLES_PAGINATOR_QUERY, {
    variables: {
      page,
      limit
    }
  })

  let articleMarkup;

  const showMore = () => setLimit((prev) => prev + 10);

  if (!getArticlesWithPagination) {
    articleMarkup = <h2>{t('news.loading')}</h2>
  } else {
    const { articles, paginator } = getArticlesWithPagination

    articleMarkup = (
      <>
        <div className="page__article">
          <Transition.Group>
            {articles && articles.map(article => (
              <ArticleCard key={article.id} article={article} />
            ))}
          </Transition.Group>
        </div>
        {paginator.articleCount > limit
          ? <button className="page__mainLink" onClick={showMore}>{t('news.cta')}</button>
          : null}
      </>
    )
  }

  return (
    <div className="page">
      <h1 className="page__headline home__headline">{t('news.headline')}</h1>
      {articleMarkup}
    </div>
  );
}

export default Articles;