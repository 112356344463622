import React from 'react';
import { useTranslation } from 'react-i18next';

const AdditionalServices = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page">
        <h1 className="page__headline">{t('services.service4.headline')}</h1>
        <div className="page__content-container">
          <p className="page__content">{t('services.service4.list.headline')}</p>
          <ul className="page__content">
            <li className="page__content--list">{t('services.service4.list.item1')}</li>
            <li className="page__content--list">{t('services.service4.list.item2')}</li>
            <li className="page__content--list">{t('services.service4.list.item3')}</li>
            <li className="page__content--list">{t('services.service4.list.item4')}</li>
            <li className="page__content--list">{t('services.service4.list.item5')}</li>
            <li className="page__content--list">{t('services.service4.list.item6')}</li>
          </ul>
          <div class="page__logos"><div><img src="/images/mbank.png" alt="mBank" /></div></div>
        </div>
      </div>
    </>
  );
}

export default AdditionalServices;