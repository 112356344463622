import ReactDOM from 'react-dom';
import ApolloProvider from './ApolloProvider'

import './styles/index.scss';
import './styles/menu.scss';
import './styles/banner.scss';
import './styles/page.scss';
import './styles/footer.scss';
import './styles/articles.scss';
import './styles/contact.scss';

import './i18n';

ReactDOM.render(
  ApolloProvider,
  document.getElementById('root')
);
