import React from 'react';

const Hamburger = (props) => {
  return (
    <div
      className={`menu__hamburger ${props.isActive ? "active" : ""}`}
      onClick={props.click}
    >
      <span className="bar bar1"></span>
      <span className="bar bar2"></span>
      <span className="bar bar3"></span>
    </div>
  );
}

export default Hamburger;