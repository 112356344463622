import React, { lazy } from 'react';
import { BrowserRouter as Router, Route, } from 'react-router-dom';

import Admin from './pages/Admin';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Accountancy from './pages/Accountancy';
import DriversTime from './pages/DriversTime';
import HR from './pages/HR';
import BHP from './pages/BHP';
import AdditionalServices from './pages/AdditionalServices';
import PrivacyPolicy from './pages/PrivacyPolicy';
import SingleArticle from './pages/SingleArticle';
import Contact from './pages/Contact';
import Articles from './pages/Articles';

import Menu from './components/Menu';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <div className="root">
        <Menu />
        <Route exact path="/" component={Home} />
        <Route exact path="/admin" component={Admin} />
        <Route exact path="/o-firmie" component={About} />
        <Route exact path="/uslugi" component={Services} />
        <Route exact path="/uslugi/ksiegowosc" component={Accountancy} />
        <Route exact path="/uslugi/rozliczanie-czasu-pracy-kierowcow" component={DriversTime} />
        <Route exact path="/uslugi/kadry-i-place" component={HR} />
        <Route exact path="/uslugi/szkolenia-bhp" component={BHP} />
        <Route exact path="/uslugi/uslugi-dodatkowe" component={AdditionalServices} />
        <Route exact path="/aktualnosci" component={Articles} />
        <Route exact path="/aktualnosci/:articleId" component={SingleArticle} />
        <Route exact path="/polityka-prywatnosci" component={PrivacyPolicy} />
        <Route exact path="/kontakt" component={Contact} />
        <Footer />
        <ScrollToTop />
      </div>
    </Router>
  );
}

export default App;
